exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-default-404-js": () => import("./../../../src/pages/default404.js" /* webpackChunkName: "component---src-pages-default-404-js" */),
  "component---src-pages-getqmobile-js": () => import("./../../../src/pages/getqmobile.js" /* webpackChunkName: "component---src-pages-getqmobile-js" */),
  "component---src-pages-howwecanhelp-js": () => import("./../../../src/pages/howwecanhelp.js" /* webpackChunkName: "component---src-pages-howwecanhelp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mhd-privacy-policy-js": () => import("./../../../src/pages/mhd-privacy-policy.js" /* webpackChunkName: "component---src-pages-mhd-privacy-policy-js" */),
  "component---src-pages-pathrouter-js": () => import("./../../../src/pages/pathrouter.js" /* webpackChunkName: "component---src-pages-pathrouter-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-financia-js": () => import("./../../../src/pages/products/financia.js" /* webpackChunkName: "component---src-pages-products-financia-js" */),
  "component---src-pages-products-qapps-js": () => import("./../../../src/pages/products/qapps.js" /* webpackChunkName: "component---src-pages-products-qapps-js" */),
  "component---src-pages-products-qsafe-js": () => import("./../../../src/pages/products/qsafe.js" /* webpackChunkName: "component---src-pages-products-qsafe-js" */),
  "component---src-pages-products-sobre-js": () => import("./../../../src/pages/products/sobre.js" /* webpackChunkName: "component---src-pages-products-sobre-js" */),
  "component---src-pages-products-tapio-js": () => import("./../../../src/pages/products/tapio.js" /* webpackChunkName: "component---src-pages-products-tapio-js" */),
  "component---src-pages-qsafe-js": () => import("./../../../src/pages/qsafe.js" /* webpackChunkName: "component---src-pages-qsafe-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

